import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'

const Index = ({ data }) => (
  <Layout
    pageTitle='Hem'
    backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
  >
    <oma-grid-row class='row row--with-auto-margin'>
      <section className='section section--with-background'>
        <h1 className='heading page__heading'>
          Switsbake - ett bageri med anor från 20-talet
        </h1>
        <p className='section__text'>
          Välkommen till Switsbake! Vi gillar verkligen att baka. Vi bakar allt
          från muffins, mjuka kakor, pajer, traybakes, kondisbitar, tårtbottnar
          och produkter för glassindustrin. Vi vänder oss till
          dagligvaruhandeln, Foodservice och industrin. Med oss blir det både
          gott och enkelt.
        </p>
        <h2 className='section__heading'>Värderingar</h2>
        <p className='section__text'>
          Dagligen ägnar sig våra ambitiösa och erfarna medarbetare åt att
          utveckla och baka de godaste produkterna av högkvalitativa råvaror som
          motsvarar våra kunders förväntningar.
        </p>
        <p className='section__text'>
          Vi är oerhört tacksamma för våra kunders, konsumenters samt
          medarbetares feedback, vilket hjälper och stärker oss i
          vidareutvecklingen av vår verksamhet och våra produkter.
        </p>
        <h2 className='section__heading'>Kvalitet och miljö</h2>
        <p className='section__text'>
          Switsbake är certifierat enligt BRC, British Retail Consortium. En
          standard som är anpassad till de krav som ställs på livsmedelsindustri
          och detaljister för att säkerställa en hög matkvalitet. Standarden
          utvecklades av de största detaljhandelskedjorna i Storbritannien för
          att ha möjlighet att utvärdera leverantörer av produkter till sina EMV
          (egna märkesvaror).
        </p>
        <p className='section__text'>
          BRC ställer krav på bland annat anläggning, produkt- och
          processtyrning samt hygien för att garantera bra mat. Ett krav är att
          företaget tillämpar HACCP, ett amerikanskt program för att minimera
          risken att tillverkningen går fel och maten blir olämplig att äta.
          Företaget gör ett antal riskanalyser, dokumenterar dessa, vidtar
          åtgärder och gör sedan regelbundna kontroller av resultatet.
        </p>
        <p className='section__text'>
          Varje år görs en revision av en auktoriserad BRC-revisor som
          kontrollerar företaget mot de krav som ingår i standarden.
        </p>
        <p className='section__text'>
          Den senaste revisionen på Switsbake utfördes i januari 2022. Switsbake
          erhöll då högsta betyg A.
        </p>
        <h2 className='section__heading'>Switsbakes miljöarbete</h2>
        <p className='section__text'>
          I vår verksamhet ska vi utnyttja naturens resurser så skonsamt som
          möjligt för att minimera miljöbelastningen och genom detta medverka
          till ett ekologiskt hållbart samhälle. Målet är att hushålla med
          råvaror och energi på bästa tänkbara sätt, utan att göra avkall på
          kvaliteten.
        </p>
        <p className='section__text'>Detta gör vi genom att:</p>
        <ul>
          <li>
            Planera verksamheten och utifrån detta utarbeta mål och
            handlingsplaner som följs upp.
          </li>
          <li>Minska förbrukning av förpackningsmaterial.</li>
          <li>
            Öka kunskapen hos alla anställda, skapa ansvar, väga in miljö,
            kvalitet och hälsa i sina arbetsuppgifter.
          </li>
          <li>
            Arbeta för ständiga förbättringar och förebyggande åtgärder inom
            miljö, kvalitet och arbetsmiljö.
          </li>
          <li>Följa lagar och avtal.</li>
        </ul>
      </section>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "chocolate-fika.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
  }
`

export default Index
